// GlobalState.js
import React, { createContext, useContext, useState } from 'react';
import Alert from '@/Shared/Alert';

// Create a context
const GlobalStateContext = createContext();

const GlobalStateProvider = ({ children }) => {
    const [alertMessage, setAlertMessage] = useState(null);

    return (
        <GlobalStateContext.Provider value={{ alertMessage, setAlertMessage }}>
            {children}
            <Alert
                onClose={() => setAlertMessage(null)}
                isOpen={!!alertMessage}
                message={alertMessage?.message}
                positive={!!alertMessage?.positive}
            />
        </GlobalStateContext.Provider>
    );
};

const useGlobalState = () => {
    const context = useContext(GlobalStateContext);
    if (context === undefined) {
        throw new Error('useGlobalState must be used within a GlobalStateProvider');
    }
    return context;
};

export { GlobalStateProvider, useGlobalState };
