import "../css/app.css";
import React from "react";
import { createRoot } from "react-dom/client";
import { createInertiaApp } from "@inertiajs/react";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { GlobalStateProvider } from '@/Shared/Hooks/GlobalState';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

const appName = window.document.getElementsByTagName("title")[0]?.innerText || "Laravel";

createInertiaApp({
    progress: {
        color: "#5e35b1",
    },
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.jsx`,
            import.meta.glob("./Pages/**/**/*.jsx")
        ),
    setup({ el, App, props }) {
        Sentry.init({
            dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
            environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
        });

        const root = createRoot(el);
        root.render(
            <GlobalStateProvider>
                <App {...props} />
            </GlobalStateProvider>
        );
    },
});
